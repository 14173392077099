import type {modeType} from '@smart/editorial-components';
import {HeroStage, type HeroStageType} from '@smart/editorial-components';
import type {HeroStageItem} from '@smart/editorial-components/dist/components/hero-stage/types';
import type {HeroStageContent, LinkContent} from '@smart/website-aem-utils';
import {
  createLegalDisclaimerProps,
  createLinkProps,
} from '@smart/website-aem-utils';
import type {BackgroundColorOption} from '@smart/website-aem-utils/src/contents/create-hero-stage-content';
import * as React from 'react';
import {useFeatureServices} from '../hooks/use-feature-services';
import {PageModelContext} from './page';

export interface HeroStageWrapperProps {
  readonly content: HeroStageContent;
  setHeaderBackground?: (
    template: 'award' | 'offer' | 'campaign' | 'model',
    color?: string,
  ) => void;
  readonly isBannerVisible: boolean;
  readonly isFirstContentOnPage: boolean;
  readonly cdnOrigin: string;
}

const isTextLinkProps = (param: string | LinkContent): param is LinkContent =>
  typeof param !== `string`;

const customVideoStylesBaseUrl = process.env.CUSTOM_VIDEO_STYLES_BASE_URL!;
export const HeroStageWrapper = React.memo(
  React.forwardRef<HTMLElement, HeroStageWrapperProps>(
    (
      {
        content,
        cdnOrigin,
        isBannerVisible,
        setHeaderBackground,
        isFirstContentOnPage,
      },
      forwardedRef,
    ) => {
      const pageModel = React.useContext(PageModelContext);

      const {languageTag = ``, marketId = ``} = pageModel?.locale || {};

      const {endpointDirectory, loginService, historyService, pubSubService} =
        useFeatureServices();

      const props: HeroStageType = {
        variant: content.variant,
        mode: content.modeSelector as modeType,
        isBannerVisible,
        isFirstContentOnPage,
        isFullHeight: content.fullHeight,
        showBottomArrowIndicator: content.showBottomArrowIndicator,
        discoverMoreText: content.discoverMoreText,
        items: content.items.map(
          // eslint-disable-next-line complexity
          (
            {
              template = `campaign`,
              imageLandscapeUrl,
              imagePortraitUrl,
              videoLandscapeUrl,
              videoPortraitUrl,
              videoPosterImageUrl,
              mediaAltText,
              price,
              subline,
              headline,
              logo,
              subHeadline,
              fromLabel,
              primaryLink,
              secondaryLink,
              badges,
              linkType,
              backgroundColorOption,
              subText,
              subscribePopupButtonText,
              renderSubscribePopupButton,
              contentTag,
              priceRichText,
              highlights,
              logos,
            },
            index,
          ) => {
            const commonPublicField = {
              headline,
              subHeadline,
              linkType,
              fromLabel,
              price,
              subText,
              template,
              renderSubscribePopupButton,
              subscribePopupButtonText,
              contentTag,
              subline:
                subline && isTextLinkProps(subline)
                  ? createLinkProps({
                      historyService,
                      content: subline,
                      websiteTrackingData: {
                        type: `navigation`,
                        region: `stage_hero`,
                        name: `${subline.label}.${index}`,
                      },
                      envName: endpointDirectory.envName,
                      loginState: loginService?.loginState,
                      languageTag,
                      marketId,
                    })
                  : subline || undefined,
              priceRichText,
              highlights,
              logos: logos?.length
                ? logos.map((logoItem) => {
                    return {
                      logoDisclaimerReference: logoItem.logoDisclaimerReference,
                      url: logoItem.url || ``,
                    };
                  })
                : [],
            };
            const commonFields =
              linkType === `badge`
                ? {
                    ...commonPublicField,
                    badges: badges
                      ? {
                          firstBadgeItem: {
                            image: badges.firstBadgeItem?.image,
                            imageAltText: badges.firstBadgeItem?.imageAltText,
                            link: badges.firstBadgeItem?.link
                              ? createLinkProps({
                                  historyService,
                                  content: badges.firstBadgeItem?.link,
                                  websiteTrackingData: {
                                    type: `navigation`,
                                    region: `stage_hero`,
                                    name: `${badges.firstBadgeItem.link.label}.${index}`,
                                  },
                                  envName: endpointDirectory.envName,
                                  loginState: loginService?.loginState,
                                  languageTag,
                                  marketId,
                                })
                              : undefined,
                          },
                          secondBadgeItem: {
                            image: badges.secondBadgeItem?.image,
                            imageAltText: badges.secondBadgeItem?.imageAltText,
                            link: badges.secondBadgeItem?.link
                              ? createLinkProps({
                                  historyService,
                                  content: badges.secondBadgeItem?.link,
                                  websiteTrackingData: {
                                    type: `navigation`,
                                    region: `stage_hero`,
                                    name: `${badges.secondBadgeItem.link.label}.${index}`,
                                  },
                                  envName: endpointDirectory.envName,
                                  loginState: loginService?.loginState,
                                  languageTag,
                                  marketId,
                                })
                              : undefined,
                          },
                          layout: badges.layout,
                        }
                      : undefined,
                  }
                : {
                    ...commonPublicField,
                    primaryLink: primaryLink
                      ? createLinkProps({
                          historyService,
                          content: primaryLink,
                          pubSubService,
                          websiteTrackingData: {
                            type: `navigation`,
                            region: `stage_hero`,
                            name: `${primaryLink.label}.${index}`,
                          },
                          envName: endpointDirectory.envName,
                          loginState: loginService?.loginState,
                          languageTag,
                          marketId,
                        })
                      : undefined,
                    secondaryLink: secondaryLink
                      ? createLinkProps({
                          historyService,
                          content: secondaryLink,
                          pubSubService,
                          websiteTrackingData: {
                            type: `navigation`,
                            region: `stage_hero`,
                            name: `${secondaryLink.label}.${index}`,
                          },
                          envName: endpointDirectory.envName,
                          loginState: loginService?.loginState,
                          languageTag,
                          marketId,
                        })
                      : undefined,
                  };

            if (template === `offer` || template === `award`) {
              return {
                ...commonFields,
                backgroundColorOption:
                  backgroundColorOption as BackgroundColorOption,
                landscapeImage: {
                  imageUrl: imageLandscapeUrl || ``,
                  imageAltText: mediaAltText || ``,
                },
                logo: logo
                  ? {
                      size: logo.size,
                      url: logo.url || ``,
                      link: logo.link
                        ? createLinkProps({
                            historyService,
                            content: logo.link,
                          })
                        : undefined,
                    }
                  : undefined,
                portraitImage: {
                  imageUrl: imagePortraitUrl || ``,
                  imageAltText: mediaAltText || ``,
                },
              } as HeroStageItem;
            } else {
              if (
                videoLandscapeUrl ||
                videoPortraitUrl ||
                videoPosterImageUrl
              ) {
                return {
                  ...commonFields,
                  template,
                  customVideoStylesBaseUrl: new URL(
                    customVideoStylesBaseUrl,
                    cdnOrigin,
                  ).href,
                  video: {
                    landscapeUrl: videoLandscapeUrl || ``,
                    portraitUrl: videoPortraitUrl || ``,
                    posterImageUrl: videoPosterImageUrl || ``,
                    altText: mediaAltText || ``,
                  },
                };
              }
              return {
                ...commonFields,
                template,
                landscapeImage: {
                  imageUrl: imageLandscapeUrl || ``,
                  imageAltText: mediaAltText || ``,
                },
                portraitImage: {
                  imageUrl: imagePortraitUrl || ``,
                  imageAltText: mediaAltText || ``,
                },
              };
            }
          },
        ),
        legalDisclaimers: createLegalDisclaimerProps(
          content.legalDisclaimers,
          historyService,
        ),
      };

      return (
        <HeroStage
          {...props}
          setHeaderBackground={setHeaderBackground}
          ref={forwardedRef}
        />
      );
    },
  ),
  (prevProps, nextProps) =>
    prevProps.content.id === nextProps.content.id &&
    prevProps.cdnOrigin === nextProps.cdnOrigin &&
    prevProps.isBannerVisible === nextProps.isBannerVisible &&
    prevProps.isFirstContentOnPage === nextProps.isFirstContentOnPage,
);

HeroStageWrapper.displayName = `HeroStageWrapper`;
